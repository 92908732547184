import Theme from '@/components/cms/Theme';

import DynamicComponent from './DynamicComponent';

const ThemeWrapper = ({ blok }) => {
  const {
    content = [],
    variant,
    applyBackground,
    colorBackgroundPreset,
    colorBackground,
    colorPrimaryPreset,
    colorPrimary
  } = blok;

  return (
    <Theme
      variant={variant}
      applyBackground={applyBackground}
      colorBackground={colorBackground || colorBackgroundPreset}
      colorPrimary={colorPrimary || colorPrimaryPreset}
    >
      {content.map((block) => (
        <DynamicComponent blok={block} key={block._uid} />
      ))}
    </Theme>
  );
};
export default ThemeWrapper;
